export enum NavbarKey {
  BROWSE = '/',
  CHANNELS = '/channels',
  MOVIES = '/movies',
  TV_SHOW = '/tv_show',
  MUSIC = '/music',
  SEARCH = '/search',
}

export enum Language {
  ES = 'es',
  EN = 'en',
}

export enum ContentType {
  BROWSE = 'browse',
  EPISODE = 'episode',
  MOVIE = 'movie',
  SERIES = 'series',
  TV = 'tv',
  MUSIC = 'music',
}

export type AppContextType = {
  isMenuOpen: boolean;
  setIsMenuOpen: (flag: boolean) => void;
  isWishlistModalOpen: boolean;
  setIsWishlistModalOpen: (flag: boolean) => void;
  onCancelWishlistModal: () => void;
  onAcceptWishlistModal: () => void;
  currentNavbarKey: NavbarKey;
  setCurrentNavbarKey: (key: NavbarKey) => void;
  searchCriteria: string;
  setSearchCriteria: (criteria: string) => void;
};

export type ChannelContextType = {
  nowPlaying: any;
  playlists;
  mainPlaylist;
  setNowPlaying: (content: any) => void;
  setPlaylists: (playlists: any[]) => void;
  setMainPlaylist: (playlist: any) => void;
};
