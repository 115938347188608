// ----------------------------------------------------------------------
export const PATH_PAGE = {
  root: '/',
  movies: '/movies',
  tv_show: '/tv_show',
  music: '/music',
  channels: '/channels',
  series: {
    list: (slug: string) => `/series/${slug}`,
  },
  season: {
    list: (slug: string, season: number) => `/series/${slug}/season/${season}`,
  },
  episode: {
    view: (slug: string, season: number, episode: number) =>
      `/series/${slug}/season/${season}/episode/${episode}`,
  },
  page404: '/404',
  page500: '/500',
};
